/**
 * @class FormFooter
 */

import * as React from 'react';
import { isMobile, isIOS } from 'react-device-detect';

import auraStyle from '@mmodal/aura-web-style/dist/css/styles.module.css';
import style from './form-footer.module.scss';

export type FormFooterProps = {
  onSaveClick: (addAnotherSelected: boolean) => void;
  onCancelClick: () => void;
  showAddAnother: boolean;
  addAnotherSelected?: boolean;
  saveEnabled: boolean;
  saveText?: string;
  cancelText?: string;
  tip?: string;
  tipDisabled?: boolean;
  hideSave?: boolean;
};

export type FormFooterState = {
  addAnother: boolean;
};

export default class FormFooter extends React.Component<FormFooterProps, FormFooterState> {
  constructor(props: FormFooterProps) {
    super(props);
    this.state = {
      addAnother: false      
    };
  }

  componentDidUpdate(prevProps: FormFooterProps) {
    if (prevProps.addAnotherSelected !== this.props.addAnotherSelected) {
      this.setState({ addAnother: this.props.addAnotherSelected || false });
    }
  }

  render() {
    return (
      <div className={auraStyle['aura-dark-footer']}>
        <div className={isMobile ? style['aura-footer-content'] : auraStyle['aura-footer-content']}>
        <button className={auraStyle['aura-dark-button']}
            data-testid={'cancel-button'}
            onClick={this.props.onCancelClick}>
            {this.props.cancelText || 'Cancel'}
        </button>
        { !this.props.hideSave && 
          // <span data-tip={this.props.tip}
          //   data-tip-disable={this.props.tip && this.props.tipDisabled}
          //   data-border={true}
          //   data-border-color="#444">

          <button className={auraStyle['aura-primary-button']}
            disabled={!this.props.saveEnabled}
            data-testid={'save-button'}
            onClick={() => this.props.onSaveClick(this.state.addAnother)}>
            {this.props.saveText || 'Save'}
          </button>
          // </span>
        }
          {this.props.showAddAnother ? (
            <h5 style={{
              color: 'white',
              width: 'auto',
              marginRight: '30px'
            }}>
              Add another
            </h5>
          ) : null}
          {this.props.showAddAnother ? (
            <input type='checkbox'
              data-testid={'add-another-check'}
              style={ isIOS ? { width: '1.625rem', height: '1.625rem'} : { width: 'auto'}}
              checked={this.state.addAnother}
              id='addAnother'
              onChange={this.addAnotherChange}
            />
          ) : null}
          {this.props.children}
        </div>
      </div>
    );
  }

  private addAnotherChange = (e: React.ChangeEvent) => {
    const select = (e.target as HTMLInputElement);
    this.setState({ addAnother: select.checked });
  };
}