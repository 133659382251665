import React, { useState, useEffect } from "react";

interface CustomIconComponents {
  ValidIcon: React.ReactNode;
  InvalidIcon: React.ReactNode;
}
interface PasswordProps {
  value: string;
  valueAgain?: string;
  minLength?: number;
  maxLength?: number;
  iconSize?: number;
  validColor?: string;
  invalidColor?: string;
  onChange?: (isValid: boolean) => any;
  messages?: {
    [key in RuleNames]?: string;
  };
  iconComponents?: CustomIconComponents;
}
export type RuleNames =
  | "minMaxLength"
  | "match"
  | "lowercase"
  | "letter"
  | "notEmpty"
  | "specialChar"
  | "number"
  | "capital"
  | "combined"

export interface ReactPasswordChecklistProps extends PasswordProps {
  className?: string;
  style?: React.CSSProperties;
  rules: {key: RuleNames, childRules?: RuleNames[]}[]
  rtl?: boolean;
}
const ReactPasswordChecklist: React.FC<ReactPasswordChecklistProps> = ({
  className,
  style,
  rules,
  value,
  valueAgain,
  minLength,
  maxLength,
  rtl,
  onChange,
  messages = {},
  ...remainingProps
}) => {
  const [isValid, setIsValid] = useState(false);
  const ruleDefinitions: {
    [key in RuleNames]: { valid: () => boolean; message: string };
  } = {
    minMaxLength: {
      valid: () => value.length >= (minLength || 100) && value.length <= maxLength,
      message:
        messages.minMaxLength || `${minLength}-${maxLength} characters long`,
    },
    specialChar: {
      valid: () => /[^a-zA-Z0-9_]/g.test(value),
      message: messages.specialChar || "Special character such as % # @ ! > / } [",
    },
    number: {
      valid: () => /\d/g.test(value),
      message: messages.number || "Numeric digit",
    },
    capital: {
      valid: () => (() => {
        var i = 0;
        if (value.length === 0) {
          return false;
        }
        while (i < value.length) {
          const character = value.charAt(i);
          if (character == character.toLowerCase()) {
            // Character is lowercase, numeric, or a symbol
          } else if (character == character.toUpperCase()) {
            return true;
          }
          i++;
        }
        return false;
      })(),
      message: messages.capital || "Uppercase letter",
    },
    match: {
      valid: () => value.length > 0 && value === valueAgain,
      message: messages.match || "New password confirmed",
    },
    lowercase: {
      valid: () => (() => {
        var i = 0;
        if (value.length === 0) {
          return false;
        }
        while (i < value.length) {
          const character = value.charAt(i);
          if (character == character.toUpperCase()) {
            // Character is lowercase, numeric, or a symbol
          } else if (character == character.toLowerCase()) {
            return true;
          }
          i++;
        }
        return false;
      })(),
      message: messages.lowercase || "Lowercase letter",
    },
    letter: {
      valid: () => /[a-zA-Z]/g.test(value),
      message: messages.letter || "Password has a letter.",
    },
    notEmpty: {
      valid: () => Boolean(value.length > 0 && valueAgain && valueAgain.length > 0),
      message: messages.notEmpty || "Password fields are not empty.",
    },
    combined: {
      valid: () => {
        return rules.find(x => x.key === 'combined').childRules?.filter(x => ruleDefinitions[x].valid() === true)?.length >=3
      },
      message: messages.combined || "Include at least 3 of the following:",
    },
  };
  const enabledRules = rules.filter((rule) =>
    Boolean(ruleDefinitions[rule.key])
  );

  const [key, setKey] = useState<string>();
  useEffect(() => {
    if (enabledRules.every((rule) => ruleDefinitions[rule.key].valid())) {
			setIsValid(true)
		} else {
			setIsValid(false)
		}
    onChange(isValid);

    setKey(new Date().toISOString())
  }, [value, valueAgain]);


  useEffect(() => {
    console.log(isValid);
    if (typeof onChange === "function") {
      onChange(isValid);
    }
  }, [isValid]);

  if (rtl) {
    className = className ? className + " rtl" : "rtl";
  }

  return (
     <ul key={key} className={className} style={{padding: '0px', ...style}}>
      {key && enabledRules.map(({ key, childRules }) => {
        return (
          <Rule
            valid={ruleDefinitions[key].valid()}
            iconSize={18}
            validColor="#4BCA81"
            invalidColor="#5A5A5A"
            {...remainingProps}
          >
            {ruleDefinitions[key].message}

            {childRules && (
              <ul className={className} style={style}>
                {childRules.map((rule) => {
                  return (
                    <Rule
                      key={rule}
                      valid={ruleDefinitions[rule].valid()}
                      iconSize={14}
                      validColor="#4BCA81"
                      invalidColor="#5A5A5A"
                      {...remainingProps}
                    >
                      {ruleDefinitions[rule].message}
                    </Rule>
                  );
                })}
              </ul>
            )}
          </Rule>
        );
      })}
    </ul>
  );
};

interface RuleProps {
  valid: boolean;
  iconSize?: number;
  iconComponents?: CustomIconComponents;
  validColor?: string;
  invalidColor?: string;
  children?: React.ReactNode;
}
const Rule: React.FC<RuleProps> = ({
  valid,
  iconSize,
  validColor,
  invalidColor,
  iconComponents,
  children,
}) => {
  return (
    <li className={valid ? "valid" : "invalid"}>
      {iconComponents ? (
        valid ? (
          iconComponents.ValidIcon
        ) : (
          iconComponents.InvalidIcon
        )
      ) : (
        <svg
          className="checklist-icon"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          width={iconSize}
          height={iconSize}
          viewBox="0 0 512 512"
          style={{ marginRight: "5px" }}
        >
          <path
            fill={valid ? validColor : invalidColor}
            d={
              valid
                ? "M432 64l-240 240-112-112-80 80 192 192 320-320z"
                : "M432 64l-240 240-112-112-80 80 192 192 320-320z"
            }
          />
        </svg>
      )}
      <span>{children}</span>
    </li>
  );
};

// const UL = styled.ul`
// 	margin: 0;
// 	padding: 0;
// 	&.rtl svg {
// 		margin-left: 5px;
// 		margin-right: 0;
// 	}
// `
// const LI = styled.li`
// 	list-style-type: none;
// 	display: flex;
// 	align-items: flex-start;
// 	margin: 2px 0;
// 	& > span {
// 		padding-top: 2px;
// 		opacity: ${(props) => (props.className === "valid" ? 1 : 0.5)};
// 		flex: 1;
// 	}
// `
// const Svg = styled.svg`
// 	margin-right: 5px;
// `

export default ReactPasswordChecklist;
