export type EventHandler<T> = (event: T) => void;

export class EventEmitter<T> {
  private readonly handlers: EventHandler<T>[] = [];

  public fire(event: T) {
    this.handlers.forEach(handler => handler(event));
  }

  public subscribe(handler: EventHandler<T>) {
    this.handlers.push(handler);
  }

  public unsubscribe(handler: EventHandler<T>) {
    this.handlers.splice(this.handlers.indexOf(handler), 1);
  }
}