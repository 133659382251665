import React, { useState, useEffect } from "react";
import { AuthStore, FdSession, IProfileService } from "@mmodal/fd-api";
import { useNavigate, useLocation } from "react-router-dom";
import formStyle from "./sign-in-form.module.scss";
import auraStyle from "@mmodal/aura-web-style/dist/css/styles.module.css";
import ActivityLayer from "../activity/activity-layer";
import { useTranslation } from "react-i18next";
import PasswordChecklist from "./password-checklist";

export interface ChangePasswordProperties {
  sessionService: FdSession;
  authStore: AuthStore;
  profileService: IProfileService;
  fromLogin: boolean;
}

export default function ChangePassword(props: ChangePasswordProperties) {
  const { t } = useTranslation();
  const [loading, setIsloading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>(null);
  const [minPasswordLength, setMinPasswordLength] = useState(8);
  const [rules, setRules] = useState([]);
  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
    newPassword: "",
    confirmNewPassword: "",
    cdsUrl: "",
    cdsAccount: "",
  });

  const [passwordValid, setPasswordValid] = useState(false);
  const [src, setSrc] = useState(null);
  const [cid, setCid] = useState(null);

  useEffect(() => {
    setIsloading(true);
    props.profileService.getProfile(true, true).then(async (profile) => {
      setMinPasswordLength(profile.userDefaults.minPasswordLength);
      setRules(
        profile.userDefaults.requireComplexPassword
          ? [
              { key: "match" },
              { key: "minMaxLength" },
              {
                key: "combined",
                childRules: ["lowercase", "capital", "number", "specialChar"],
              },
            ]
          : [{ key: "match" }, { key: "minMaxLength" }]
      );
      setCredentials({
        ...credentials,
        ...{
          username: profile.authenticatedUserName,
          cdsUrl: profile.cdsUrl,
          cdsAccount: profile.cdsAccount,
        },
      });
      setIsloading(false);
    });
  }, []);

  const [invalid, setInvalid] = useState(true);

  useEffect(() => {
    let valid = false;

    if (
      credentials.username &&
      credentials.password &&
      credentials.newPassword &&
      credentials.confirmNewPassword &&
      passwordValid
    ) {
      valid = true;
    }

    setInvalid(!valid);
  }, [credentials, passwordValid]);

  let navigate = useNavigate();
  let location = useLocation();

  let { from } = (location.state as any) || { from: { pathname: "/" } };

  useEffect(() => {
    setSrc(new URLSearchParams(location.search).get("src") || null);
    setCid(new URLSearchParams(location.search).get("cid") || null);
  }, [location.search]);

  const handleChangePasswordSuccess = () => {
    if (src === "extension") {
      chrome.runtime.sendMessage(cid, "changePassword-success");
    } else {
      navigate(from, { replace: true });
    }
  };

  const close = async () => {
    if (src === "extension") {
      chrome.runtime.sendMessage(cid, "changePassword-close");
    } else {
      if (props.fromLogin) {
        await props.authStore.logout();
        navigate({ pathname: "/login" });
      } else {
        navigate({ pathname: "/user-settings" });
      }
    }
  };

  const handleChangePasswordException = (e: any) => {
    setIsloading(false);
    setErrorMessage(e.data.message || JSON.stringify(e));
  };

  const changePassword = async () => {
    setIsloading(true);
    setErrorMessage(null);

    await props.profileService
      .changePassword({
        username: credentials.username,
        password: credentials.password,
        newPassword: credentials.newPassword,
        cdsAccount: credentials.cdsAccount,
        cdsUrl: credentials.cdsUrl,
      })
      .then(handleChangePasswordSuccess)
      .catch(handleChangePasswordException);
  };

  const handleKeyDown = async (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      await changePassword();
    }
  };

  return (
    <div className={formStyle["sign-in-container"]}>
      <div className={formStyle["sign-in-content"]}>
        {/* <img
          className={formStyle["logo"]}
          alt='Logo'
          width="384" height="74"
          src='/icons/login/logo_light.svg' /> */}
        <h2 style={{ textAlign: "center" }}>Change Password</h2>

        {!loading && (
          <ActivityLayer activityRunning={loading}>
            <div className={formStyle["login-form"]}>

              <div className={formStyle["form-entry"]}>
                <label className="aura-input-label">
                  {t("changePassword.currentPassword")}
                </label>
                <input
                  id="password"
                  data-testid="signin-password"
                  value={credentials.password}
                  type="password"
                  title={t("changePassword.currentPassword")}
                  className={auraStyle["aura-input"]}
                  disabled={loading}
                  onKeyDown={handleKeyDown}
                  onChange={(e) => {
                    setCredentials({
                      ...credentials,
                      password: e.target.value,
                    });
                  }}
                />
              </div>

              <div className={formStyle["form-entry"]}>
                <label className="aura-input-label">
                  {t("changePassword.newPassword")}
                </label>
                <input
                  id="password"
                  data-testid="signin-password"
                  value={credentials.newPassword}
                  type="password"
                  title={t("changePassword.newPassword")}
                  className={auraStyle["aura-input"]}
                  disabled={loading}
                  onKeyDown={handleKeyDown}
                  onChange={(e) => {
                    setCredentials({
                      ...credentials,
                      newPassword: e.target.value,
                    });
                  }}
                />
              </div>

              <div className={formStyle["form-entry"]}>
                <label className="aura-input-label">
                  {t("changePassword.confirmNewPassword")}
                </label>
                <input
                  id="password"
                  data-testid="signin-password"
                  value={credentials.confirmNewPassword}
                  type="password"
                  title={t("changePassword.confirmNewPassword")}
                  className={auraStyle["aura-input"]}
                  disabled={loading}
                  onKeyDown={handleKeyDown}
                  onChange={(e) => {
                    setCredentials({
                      ...credentials,
                      confirmNewPassword: e.target.value,
                    });
                  }}
                />
              </div>

              <div className={formStyle["form-entry"]}>
                <label className="aura-input-label">
                  {t("changePassword.passwordRequirements")}
                </label>

              <PasswordChecklist
                style={{
                  margin: "0px 5px",
                  fontSize: "14px",
                  listStyle: "none",
                  color: "white",
                }}
                rules={rules}
                minLength={minPasswordLength}
                maxLength={20}
                value={credentials.newPassword}
                valueAgain={credentials.confirmNewPassword}
                onChange={(isValid) => {
                  setPasswordValid(isValid);
                }}
              />

              </div>

              <div
                className={formStyle["form-entry"]}
                style={{ display: "flex", gap: "10px" }}
              >
                <button
                  onClick={close}
                  data-testid="cancelButton"
                  className={[auraStyle["aura-dark-button"]].join(" ")}
                >
                  {t("changePassword.cancel")}
                </button>
                <button
                  onClick={changePassword}
                  data-testid="changePasswordButton"
                  disabled={loading || invalid}
                  className={[auraStyle["aura-primary-button"]].join(" ")}
                >
                  {t("changePassword.changePassword")}
                </button>
              </div>
            </div>
          </ActivityLayer>
        )}
        {loading && (
          <div className={formStyle["auto-signin-container"]}>
            <img
              alt="Logo"
              className={formStyle["auto-signin-loader"]}
              src="/icons/login/loader.svg"
            />
            <span className={formStyle["auto-signin-text"]}>
              {t("signin.waitInstructions")}
            </span>
          </div>
        )}
        <div className={formStyle["aura-form-error-message-container"]}>
          {errorMessage && (
            <div
              className={[
                auraStyle["aura-form-error-message"],
                formStyle["aura-form-error-message"],
              ].join(" ")}
              data-testid="error-message"
            >
              {errorMessage}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
