import './App.scss';
import '@mmodal/aura-icons/dist/css/auraicons.css';
import { config } from './config';
import { ConsoleLogger } from './logging/console-logger';
import { ErrorBoundary } from 'react-error-boundary'
import { handleAuthError } from './components/util/error-handler';
import { LeftNav } from './components/primary-nav/left-nav';
import { NotificationCenter } from './notifications/notification-center';
import { ServiceFactory, AuthStore, IUserProfile, FDWebConfig } from '@mmodal/fd-api';
import { Routes, Route, Navigate, RouteProps, useParams, useNavigate, useLocation } from "react-router";
import { WebStorage } from './storage/web-storage';
import ErrorFallback from './components/error/error-fallback';
import React, { useState, useEffect, Fragment, Suspense } from 'react';
import ReactTooltip from 'react-tooltip';
import ToastAlertContainer from './components/alerts/toast-alert-container';
import ActivityLayer from './components/activity/activity-layer';
import ImportButtonMappingsForm from './components/button-mappings/import-button-mappings-form';
import ImportDictionaryForm from './components/dictionary/dictionary-import';
import DeleteDictionaryForm from './components/dictionary/dictionary-delete';
import ExchangeTokenForm from './components/authentication/exchange-token';
import './i18n/config'
import { Refreshable } from 'react-router-refreshable';
import ChangePassword from './components/authentication/change-password';

const AbbreviationForm = React.lazy(() => import('./components/abbreviation/abbreviation-form'));
const AbbreviationsList = React.lazy(() => import('./components/abbreviation/abbreviations-list'));
const AddSpecialtyAvailabilityForm = React.lazy(() => import('./components/specialty/add-specialty-availability-form'));
const ButtonMappingsList = React.lazy(() => import('./components/button-mappings/button-mappings-list'));
const CommandForm = React.lazy(() => import('./components/commands/command-form'));
const CommandGroupForm = React.lazy(() => import('./components/commands/command-group-form'));
const CommandMoveForm = React.lazy(() => import('./components/commands/command-move-form'));
const CommandsList = React.lazy(() => import('./components/commands/commands-list'));
const EditSpecialtyAvailabilityForm = React.lazy(() => import('./components/specialty/edit-specialty-availability-form'));
const FdDictionaryMain = React.lazy(() => import('./components/dictionary/fd-dictionary-main'));
const FormattingForm = React.lazy(() => import('./components/formatting/formatting-form'));
const ImportAbbreviationsForm = React.lazy(() => import('./components/abbreviation/abbreviations-import'));
const ImportCommandForm = React.lazy(() => import('./components/commands/command-import'));
const ButtonMappingForm = React.lazy(() => import('./components/button-mappings/button-mapping-form'));
const SignInForm = React.lazy(() => import('./components/authentication/sign-in-form'));
const SpecialtyForm = React.lazy(() => import('./components/specialty/specialty-form'));
const UserSettingsForm = React.lazy(() => import('./components/user-settings/user-settings-form'));
const WordForm = React.lazy(() => import('./components/dictionary/word-form'));
const ShortcutsList = React.lazy(() => import('./components/shortcuts/shortcuts-list'));
const ShortcutsForm = React.lazy(() => import('./components/shortcuts/shortcuts-form'));

const App = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [profile, setProfile] = useState<IUserProfile>(null);
  const [, setIsHomeAuthorized] = useState(false);
  const [passwordExpired, setPasswordExpired] = useState(false);
  const [allowedGraceLogins, ] = useState(false);

  // const [, setProfile ] = useState<IUserProfile>();
  const notificationCenter = NotificationCenter.getInstance();
  const storage = new WebStorage();
  const authStore = new AuthStore(storage);
  const fdConfig = new FDWebConfig(authStore, config.apiUrl, 'app');
  const logger = new ConsoleLogger()
  const location = useLocation();
  // const navigate = useNavigate();

  const factory = new ServiceFactory(
    config,
    authStore,
    logger,
  );

  useEffect(() => {
    authStore.isUserLoggedIn(true)
      .then((isLoggedin) => {
        if (isLoggedin) {
          authStore.getCurrentApiUser().then(() => {
            setIsLoading(false);
          });
        } else {
          setIsLoading(false);
        }
        setIsAuthenticated(isLoggedin);
      })
      .catch(console.log);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fdConfig.get().then((config) => {
      logger.info('Config: ' + JSON.stringify(config))
    });
  }, [])

  useEffect(() => {
    if (isAuthenticated) {
      setIsLoading(true);
      factory
        .getProfileService()
        .getProfile()
        .then(async (profile) => {
          setProfile(profile);
          await authStore.setIsAdmin(profile.isAdmin);
          setPasswordExpired(profile.authenticationStatus?.passwordExpired);
          setIsHomeAuthorized(
            !profile.authenticationStatus?.passwordExpired ||
            (profile.authenticationStatus?.passwordExpired &&
              profile.authenticationStatus?.graceLogins > 0 &&
              allowedGraceLogins)
          );
        })
        .catch(async (reason) => {
          if (!reason) {
            // Network error
            logger.warn(
              "Unable to retrieve user profile due to a network error. Display name will not be set."
            );
          } else if (handleAuthError(reason)) {
            await authStore.logout();
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [isAuthenticated, allowedGraceLogins]);

  return (
    <span data-reactroot
      data-testid='app-child'
      id='react-root'>
      <ReactTooltip
        effect='solid'
        place='top' />
      <ToastAlertContainer
        notificationCenter={notificationCenter} />
      {
        !isLoading &&
        <Refreshable>
        <Suspense fallback={<ActivityLayer activityRunning={true}><div>Loading</div></ActivityLayer>}>
          <Routes>
            {
              isAuthenticated && profile &&
              <Route>
                <Route path='/formatting-settings' element={
                  <AppRoute>
                    <FormattingForm
                      formattingService={factory.getFormattingSettingsService()}
                      notificationCenter={notificationCenter} />
                  </AppRoute>
                } />
                <Route path='/user-settings' element={
                  <AppRoute>
                    <UserSettingsForm userSettingsService={factory.getUserSettingsService()}
                      notificationCenter={notificationCenter} />
                  </AppRoute>
                } />
                <Route path='/abbreviations' element={
                  <AppRoute>
                    <AbbreviationsList
                      abbreviationService={factory.getAbbreviationsService()}
                      notificationCenter={notificationCenter}
                    />
                  </AppRoute>
                } />

                 <Route path='/abbreviations/replacement' element={
                  <AppRoute modal render={() => {
                    return (
                      <AbbreviationForm
                        notificationCenter={notificationCenter}
                        src={''}
                        abbreviationService={factory.getAbbreviationsService()} />
                    )
                  }} />
                } />

                <Route path='/abbreviations/replacement/:src' element={
                  <AppRoute modal render={({ src }: any) => {
                    return (
                      <AbbreviationForm
                        notificationCenter={notificationCenter}
                        src={src}
                        abbreviationService={factory.getAbbreviationsService()} />
                    )
                  }} />
                } />

                <Route path='/abbreviations/import' element={
                  <AppRoute modal render={() => {
                    return <ImportAbbreviationsForm
                      abbreviationsService={factory.getAbbreviationsService()}
                      notificationCenter={notificationCenter}
                    />
                  }} />
                } />
                <Route path='/buttonmappings' element={
                  <AppRoute render={() => {
                    return <ButtonMappingsList buttonMappingsService={factory.getButtonMappingsService()} />
                  }} />
                } />

                <Route path='/buttonmappings/import' element={
                  <AppRoute modal render={() => {
                    return (
                      <ImportButtonMappingsForm buttonMappingsService={factory.getButtonMappingsService()} notificationCenter={notificationCenter} />
                    )
                  }} />
                } />
                <Route path='/buttonmappings/mapping' element={
                  <AppRoute modal render={() => {
                    return (
                      <ButtonMappingForm
                        buttonMappingService={factory.getButtonMappingsService()}
                        commandsService={factory.getCommandsService()} />
                    )
                  }} />
                } />
                <Route path='/buttonmappings/edit' element={
                  <AppRoute modal render={({ location }) => {
                    return (
                      <ButtonMappingForm
                        buttonMappingService={factory.getButtonMappingsService()}
                        commandsService={factory.getCommandsService()}
                        buttonMapping={(location.state as any).mapping} />
                    )
                  }} />
                } />
                <Route path='/commands' element={
                  <AppRoute render={() => {
                      return <CommandsList
                        commandsService={factory.getCommandsService()}
                        notificationCenter={notificationCenter}
                      />
                  }} />
                } />

                <Route path='/commands/groups' element={
                  <AppRoute modal render={() => {
                      return <CommandGroupForm
                        commandsService={factory.getCommandsService()}
                        notificationCenter={notificationCenter}
                        displayForm={''}
                      />
                  }} />
                } />

                <Route path='/commands/groups/:displayForm' element={
                  <AppRoute modal render={({ displayForm }: any) => {
                      return <CommandGroupForm
                        commandsService={factory.getCommandsService()}
                        notificationCenter={notificationCenter}
                        displayForm={displayForm}
                      />
                  }} />
                } />
                <Route path='/commands/macros' element={
                  <AppRoute modal render={({ location }: any) => {
                      return <CommandForm
                        commandsService={factory.getCommandsService()}
                        notificationCenter={notificationCenter}
                        groupName={location.state.groupName}
                        macroID={''}
                      />
                  }} />
                } />
                <Route path='/commands/macros/:macroID' element={
                  <AppRoute modal render={({ macroID, location }: any) => {
                      return <CommandForm
                        commandsService={factory.getCommandsService()}
                        notificationCenter={notificationCenter}
                        groupName={location.state.groupName}
                        macroID={macroID}
                      />
                  }} />
                } />
                <Route path='/commands/macros/:macroID/:type' element={
                  <AppRoute modal render={({ macroID, type , location }: any) => {
                      return (<CommandMoveForm
                        commandsService={factory.getCommandsService()}
                        notificationCenter={notificationCenter}
                        sourceGroupName={location.state?.['sourceGroupName'] ?? ''}
                        sourceMacroID={macroID ?? ''}
                        macroName={location.state?.['macroName'] ?? ''}
                        type={type ?? 'copy'} />);
                  }} />
                } />
                <Route path='/commands/import' element={
                  <AppRoute modal render={({ location }: any) => {
                      return (<ImportCommandForm
                        commandsService={factory.getCommandsService()}
                        notificationCenter={notificationCenter}
                        groupName={location.state?.groupName}
                      />);
                  }} />
                } />

                <Route path='/dictionary/word' element={
                  <AppRoute modal
                    render={() => {
                      return (
                        <WordForm
                          recognitionService={factory.getRecognitionService()}
                          dictionaryService={factory.getDictionaryService()}
                          recordingService={factory.getRecordingService({ channel: profile?.audioSampleRate })}
                          notificationCenter={notificationCenter}
                          word={''}
                          disableRecording={false}
                        />
                      )
                    }}>
                  </AppRoute>
                } />

                <Route path='/dictionary/word/:word' element={
                  <AppRoute modal
                    render={({ word }: any) => {
                      return (
                        <WordForm
                          recognitionService={factory.getRecognitionService()}
                          dictionaryService={factory.getDictionaryService()}
                          recordingService={factory.getRecordingService({ channel: profile?.audioSampleRate })}
                          notificationCenter={notificationCenter}
                          word={word ?? ''}
                          disableRecording={false}
                        />
                      )
                    }}>
                  </AppRoute>
                } />

                <Route path='/dictionary' element={
                  <AppRoute render={() => {
                    return <FdDictionaryMain
                      isAdmin={profile?.isAdmin}
                      dictionaryService={factory.getDictionaryService()}
                      notificationCenter={notificationCenter}
                    />
                  }} />
                } />
                <Route path='/dictionary/import' element={
                  <AppRoute modal render={() => {
                    return (<ImportDictionaryForm
                      dictionaryService={factory.getDictionaryService()}
                      notificationCenter={notificationCenter}
                    />);
                  }} />
                } />
                <Route path='/dictionary/bulk-delete' element={
                  <AppRoute modal render={() => {
                    return (<DeleteDictionaryForm // Create a DeleteDictionaryForm
                      dictionaryService={factory.getDictionaryService()}
                      notificationCenter={notificationCenter}
                    />);
                  }} />
                } />
                <Route path='/specialty/availability' element={
                  <AppRoute
                    modal
                    render={(props) => {
                      const availabilitySpecialtyName = new URLSearchParams(props.location.search).get('specialty') as string;
                      if (availabilitySpecialtyName) {
                        return <EditSpecialtyAvailabilityForm
                          service={factory.getMedicalSpecialtyService()}
                          specialtyName={availabilitySpecialtyName}
                          notificationCenter={notificationCenter} />;
                      } else {
                        return <AddSpecialtyAvailabilityForm
                          service={factory.getMedicalSpecialtyService()}
                          notificationCenter={notificationCenter} />
                      }
                    }}
                  />
                } />
                <Route path='/specialty' element={
                  <AppRoute render={() => {
                    return <SpecialtyForm specialtyService={factory.getMedicalSpecialtyService()}
                      isGroup={profile?.isGroup}
                      notificationCenter={notificationCenter} />
                  }} />
                } />
                <Route path='/shortcuts' element={
                  <AppRoute render={() => {
                    return <ShortcutsList
                      shortcutsService={factory.getShortcutsService()}
                      notificationCenter={notificationCenter}
                    />
                  }} />
                } />
                <Route path='/shortcuts/shortcut/:shortcut?' element={
                  <AppRoute modal render={() => {
                    return <ShortcutsForm
                      shortcutsService={factory.getShortcutsService()}
                      notificationCenter={notificationCenter}
                      recordingService={factory.getRecordingService({ activeTimeout: 5000, channel: profile?.audioSampleRate })}
                      mockShortcutService={factory.getMockShortcutsService()}
                    />
                  }} />
                } />


                <Route
                    path="/change-auth-password"
                    element={
                      <ChangePassword
                        sessionService={factory.getSessionService()}
                        profileService={factory.getProfileService()}
                        fromLogin={false}
                        authStore={authStore}
                      />
                    }
                  />
              </Route>
            }


              {isAuthenticated && passwordExpired && (
                <Route
                  path="/changePassword"
                  element={
                    <ChangePassword
                      sessionService={factory.getSessionService()}
                      profileService={factory.getProfileService()}
                      fromLogin={true}
                      authStore={authStore}
                    />
                  }
                />
              )}

            <Route path='/logout' element={<Navigate to='/login' />} />


            <Route path='/login' element={<SignInForm sessionService={factory.getSessionService()}
              serviceFactory={factory}
              authStore={authStore}
              onAuthenticated={() => {
                setIsAuthenticated(true);
              }} />} />

            <Route path="/exchange-token" element={
              <ExchangeTokenForm sessionService={factory.getSessionService()}
                onAuthenticated={() => {
                  setIsAuthenticated(true);
                }}></ExchangeTokenForm>
            } />

            <Route path="/" element={<Navigate to={isAuthenticated ? "/dictionary" : "/login"}></Navigate>} />

            <Route path="*" element={
              isAuthenticated ? <Navigate to={"/dictionary"}></Navigate> : (<Navigate to={{ pathname: "/login" }} state={{ from: location }}></Navigate>)
            } />

          </Routes>
        </Suspense>
        </Refreshable>
      }
    </span>
  );

  function appErrorHandler(error: Error, info: { componentStack: string }) {
    console.error(error, info.componentStack);
    if (error.name === 'ChunkLoadError' || /Loading chunk [\d]+ failed/.test(error.message)) {
      window.location.reload();
      // The error should go. This is to fix the stale cache deployments
    }
  }

  // A wrapper for <Route> that Navigates to the login
  // screen if you're not yet authenticated.
  function AppRoute({ children, render, modal = false }: AppRouteProps) {
    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    return isAuthenticated ? (
          <ErrorBoundary FallbackComponent={ErrorFallback} onError={appErrorHandler}>
            <Fragment>
              {!modal && <LeftNav
                isAuthenticated={isAuthenticated}
                profile={profile}
                storage={authStore}
                beforeSignOutNav={async () => {
                  await notificationCenter.dismissAllNotifications();
                  await factory.getSessionService().signOut(await authStore.getCurrentUniqueId());
                  setIsAuthenticated(false);
                  setProfile(null);
                }}
              />}
              <>
                {children || (render && render({ ...params, navigate, location }))}
              </>
            </Fragment>
          </ErrorBoundary>
        ) :
          (<Navigate to={{ pathname: "/login" }} state={{ from: location }}></Navigate>)
  }

  interface AppRouteProps extends RouteProps {
    modal?: boolean;
    render?: (props: any) => JSX.Element;
  }
}


export default App;
