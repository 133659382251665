import React, { Fragment, useEffect, useState } from 'react';
import auraStyle from '@mmodal/aura-web-style/dist/css/styles.module.css';
import leftNav from './left-nav.module.scss';
import NavItem from './nav-item';
import { useLocation } from 'react-router-dom';
import { AuthStore, IUserProfile } from '@mmodal/fd-api';
import { Desktop, Mobile } from '../../responsive';
import useBus, { dispatch } from 'use-bus';
import { useDetectClickOutside } from '../util/outside-click';
import { Sources, Pages } from '../util/enums';

export interface ILeftNavProperties {
  isAuthenticated: boolean;
  profile: IUserProfile;
  storage: AuthStore;
  beforeSignOutNav?: () => Promise<void>;
}

export function LeftNav(props: ILeftNavProperties) {
  const location = useLocation();
  const [navExpanded, setNavExpanded] = useState(false);
  const [, setBackendVersion] = useState('');
  const [source] = useState(sessionStorage.getItem("source")?.toLowerCase());
  const [target] = useState(sessionStorage.getItem("target")?.toLowerCase());


  const ref = useDetectClickOutside({ onTriggered: () => {
    if(navExpanded) {
      dispatch({type:'@@ui/NAV_MENU_OUTSIDE_CLICK', payload: false});
      setNavExpanded(false);
    }
  }});

  useBus(
    '@@ui/EXPAND_NAV_MENU',
    (event) => setNavExpanded(event.payload),
    [navExpanded],
  )

  useEffect(() => {
      props.storage.getBackendVersion().then((version) => {
        setBackendVersion(version);
      });
  }, [props.storage]);

  return (
    <><Desktop>
      <div id='left-nav' className={[auraStyle['aura-dark-gray-form-container'], leftNav['left-nav-adjustment']].join(' ')}>
        <div className={auraStyle['aura-menu-logo']}>
          <div className={[auraStyle['menuLogo'], leftNav['display-name-text']].join(' ')}>
            <img
              style={{
                height: '26px',
                width: '133px',
                marginLeft: '0px',
                marginTop: '15px',
              }}
              src='/logo_fluency-direct.svg'
              alt='FD Logo' />
          </div>
          <div className={[auraStyle['aura-text'] ,leftNav['display-name-text']].join(' ')} data-testid='logged-in-name'>
            {props.profile?.fullName}
          </div>
        </div>

        <div className={auraStyle['aura-dark-divider']}
          style={{
            position: 'static'
          }}></div>

        {!props.isAuthenticated &&
          <div className={auraStyle['aura-category-list']}
            data-testid='login-category-list'>
            <NavItem to='/login'
              label='Sign In' />
          </div>}
        {props.isAuthenticated &&
          <div className={auraStyle['aura-category-list']}
            data-testid='authenticated-category-list'>
            <NavItem
              to='/dictionary'
              selected={location.pathname.includes('/dictionary')}
              label='Dictionary'
              beforeNavigation={async () => { setNavExpanded(false) }} />
            <NavItem to='/commands'
              selected={location.pathname.includes('/commands')}
              label='Commands'
              beforeNavigation={async () => { setNavExpanded(false) }} />
            <NavItem to='/abbreviations'
              selected={location.pathname.includes('/abbreviations')}
              label='Abbreviations'
              beforeNavigation={async () => { setNavExpanded(false) }} />
            <NavItem to='/buttonmappings'
              selected={location.pathname.includes('/buttonmappings')}
              label='Device Button Mappings'
              beforeNavigation={async () => { setNavExpanded(false) }} />

            <div className={auraStyle['aura-dark-divider']} style={{ position: 'static' }}></div>

            <NavItem to='/specialty'
              selected={location.pathname.includes('/specialty')}
              label='Medical Specialty'
              beforeNavigation={async () => { setNavExpanded(false) }} />
            <NavItem
              to='/formatting-settings'
              selected={location.pathname.includes('/formatting-settings')}
              label='Formatting'
              beforeNavigation={async () => { setNavExpanded(false) }} />
            {!props.profile?.isImpersonation &&
              <NavItem to='/user-settings'
                selected={location.pathname.includes('/user-settings')}
                label="Settings"
                beforeNavigation={async () => { setNavExpanded(false) }} />}
            {props.profile?.isAssistantEnabled &&
              <Fragment>
                <div className={auraStyle['aura-dark-divider']} style={{ position: 'static' }}></div>
                <NavItem
                  to='/shortcuts'
                  selected={location.pathname.includes('/shortcuts')}
                  label='Shortcuts'
                />
              </Fragment>
            }
            <div className={auraStyle['aura-dark-divider']} style={{ position: 'static' }}></div>

            <NavItem to='/logout'
              beforeNavigation={props.beforeSignOutNav}
              label='Sign Out' />
          </div>}
          
            <div style={{ margin: '0 15px'}}>
                <p>
                  <span style={{color:'lightgray', fontSize: '14px'}}>v{process.env.REACT_APP_VERSION}</span>
                  {/* <p style={{fontSize: '12px'}}>(API: v{process.env.REACT_APP_VERSION})</p> */}
                </p>
            </div>
      </div>
    </Desktop>
    <Mobile>
      {navExpanded &&
        <div id='left-nav' ref={ref} className={[auraStyle['aura-dark-gray-form-container'], leftNav['left-nav-adjustment']].join(' ')}>
          <div className={auraStyle['aura-menu-logo']}>
            <div className={[auraStyle['menuLogo'], leftNav['display-name-text']].join(' ')}>
              <img
                style={{
                  height: '36px',
                  width: '145px',
                  marginLeft: '28px',
                  marginTop: '13px',
                }}
                src='/logo_fluency-direct.svg'
                alt='FD Logo' />
            </div>
            <div className={[auraStyle['aura-text'], leftNav['display-name-text']].join(' ')}>
              {props.profile?.fullName}
            </div>
          </div>

          <div className={auraStyle['aura-dark-divider']}
            style={{
              position: 'static'
            }}></div>

          {!props.isAuthenticated &&
            <div className={auraStyle['aura-category-list']}
              data-testid='login-category-list'>
              <NavItem to='/login'
                label='Sign In'
                beforeNavigation={async () => { 
                  setNavExpanded(false);
                  dispatch({type:'@@ui/EXPAND_NAV_MENU', payload: false});
                }} />
            </div>}
          {props.isAuthenticated &&
            <div className={auraStyle['aura-category-list']}
              data-testid='authenticated-category-list'>
              {source === Sources.MobileSDK && target !== Pages.Dictionary ? null
              :
              <NavItem
                to='/dictionary'
                selected={location.pathname.includes('/dictionary')}
                label='Dictionary'
                beforeNavigation={async () => { 
                  setNavExpanded(false);
                  dispatch({type:'@@ui/EXPAND_NAV_MENU', payload: false});
                }} />}
              {source === Sources.MobileSDK && target !== Pages.Commands ? null
              :
              <NavItem to='/commands'
                selected={location.pathname.includes('/commands')}
                label='Commands'
                beforeNavigation={async () => { 
                  setNavExpanded(false);
                  dispatch({type:'@@ui/EXPAND_NAV_MENU', payload: false});
                }} />}
              {source === Sources.MobileSDK && target !== Pages.Abbreviations ? null
              :
              <NavItem to='/abbreviations'
                selected={location.pathname.includes('/abbreviations')}
                label='Abbreviations'
                beforeNavigation={async () => { 
                  setNavExpanded(false);
                  dispatch({type:'@@ui/EXPAND_NAV_MENU', payload: false});
                }} />}

              <div className={auraStyle['aura-dark-divider']} style={{ position: 'static' }}></div>
              {source === Sources.MobileSDK && target !== Pages.MedicalSpecialty ? null
              :
              <NavItem to='/specialty'
                selected={location.pathname.includes('/specialty')}
                label='Medical Specialty'
                beforeNavigation={async () => { 
                  setNavExpanded(false);
                  dispatch({type:'@@ui/EXPAND_NAV_MENU', payload: false});
                }} />}
              {source === Sources.MobileSDK && target !== Pages.Formatting? null
              :
              <NavItem
                to='/formatting-settings'
                selected={location.pathname.includes('/formatting-settings')}
                label='Formatting'
                beforeNavigation={async () => { 
                  setNavExpanded(false);
                  dispatch({type:'@@ui/EXPAND_NAV_MENU', payload: false});
                }} />}
              {!props.profile?.isImpersonation && source !== Sources.MobileSDK &&
                <NavItem to='/user-settings'
                  selected={location.pathname.includes('/user-settings')}
                  label="Settings"
                  beforeNavigation={async () => { 
                    setNavExpanded(false);
                    dispatch({type:'@@ui/EXPAND_NAV_MENU', payload: false});
                  }} />}
              {props.profile?.isAssistantEnabled && source !== Sources.MobileSDK &&
                <Fragment>
                  <div className={auraStyle['aura-dark-divider']} style={{ position: 'static' }}></div>
                  {source === Sources.MobileSDK && target !== "shortcuts" ? null
                  :
                  <NavItem
                    to='/shortcuts'
                    selected={location.pathname.includes('/shortcuts')}
                    label='Shortcuts'
                    beforeNavigation={async () => { 
                      setNavExpanded(false);
                      dispatch({type:'@@ui/EXPAND_NAV_MENU', payload: false});
                    }}/>}
                </Fragment>
              }
              {source !== Sources.MobileSDK ?
              <>
                <div className={auraStyle['aura-dark-divider']} style={{ position: 'static' }}></div>
                <NavItem to='/logout'
                  beforeNavigation={props.beforeSignOutNav}
                  label='Sign Out' />
                <div className={auraStyle['aura-dark-divider']} style={{ position: 'static' }}></div>
              </>
            : null}
            </div>}
            
            <div style={{ margin: '0px 15px'}}>
              <p>
                <span style={{color:'lightgray', fontSize: '14px'}}>v{process.env.REACT_APP_VERSION}</span>
                {/* <p style={{fontSize: '12px', margin: '4px 0px'}}>(API: v{process.env.REACT_APP_VERSION})</p> */}
              </p>
            </div>
        </div>
      }
    </Mobile></>
  );
}