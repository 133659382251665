import { ILogger } from '@mmodal/fd-api';

export class ConsoleLogger implements ILogger {
  public info(message: string, ...optionalArgs: any[]) {
    const prepended = `[${new Date(Date.now()).toString()}] - ${message}`;
    if (optionalArgs && optionalArgs.length > 0) {
      console.info(prepended, ...optionalArgs);
    } else {
      console.info(prepended);
    }
  }

  public log(message: string, ...optionalArgs: any[]) {
    const prepended = `[${new Date(Date.now()).toString()}] - ${message}`;
    if (optionalArgs && optionalArgs.length > 0) {
      console.log(prepended, ...optionalArgs);
    } else {
      console.log(prepended);
    }
  }

  public warn(message: string, ...optionalArgs: any[]) {
    const prepended = `[${new Date(Date.now()).toString()}] - ${message}`;
    if (optionalArgs && optionalArgs.length > 0) {
      console.warn(prepended, ...optionalArgs);
    } else {
      console.warn(prepended);
    }
  }

  public error(message: string, ...optionalArgs: any[]) {
    const prepended = `[${new Date(Date.now()).toString()}] - ${JSON.stringify(message)}`;
    if (optionalArgs && optionalArgs.length > 0) {
      console.error(prepended, ...optionalArgs);
    } else {
      console.error(prepended);
    }
  }
}
