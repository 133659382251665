import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FdSession } from '@mmodal/fd-api';
import auraStyle from '@mmodal/aura-web-style/dist/css/styles.module.css';
import style from './exchange-token.module.scss';
import { useLocation } from 'react-router';
import qs from 'qs';
import { v4 as uniqueId } from 'uuid';

export interface IExchangeTokenState {
  ticket: string;
  target: string;
  sso: boolean;
  source: string;
}

export interface ExchangeTokenFormProperties {
  sessionService: FdSession;
  onAuthenticated: (displayName: string) => void;
}

export default function ExchangeTokenForm(props: ExchangeTokenFormProperties) {
  const [signingIn, setSigningIn] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>(null);
  const navigate = useNavigate();
  const location = useLocation();


  useEffect(() => {
    const hash: IExchangeTokenState = qs.parse(location.hash?.substring(1)) as unknown as IExchangeTokenState;
    setSigningIn(true);
    if(hash) {
      signIn(hash.ticket, hash.sso, hash.target, hash.source);
    }
  }, [location.hash]);

  const handleSignedIn = (result: { success: boolean, message?: string }, target: string, source: string) => {
    if (!result.success) {
      setSigningIn(false);
      setErrorMessage(result.message);
    }

    if (result.success) {
      props.onAuthenticated?.('');
      sessionStorage.setItem("source", source);
      sessionStorage.setItem("target", target);
      // if target is empty string set it to root
      // if target doesn't start with / set it to /target
      // otherwise just use target
      navigate(target ? target.startsWith('/') ? target : `/${target}` : '/');
    }
  }

  const handleSignInException = (e: any) => {
    setSigningIn(false);
    setErrorMessage(e.message);
  }

  const signIn = async (ticket: string, sso: boolean, target: string, source: string) => {
    setSigningIn(true);
    setErrorMessage(null);
    if(sso) {
      await props.sessionService.ssoLoginToken({ticket, sso})
      .then((result => handleSignedIn(result, target, source)))
      .catch(handleSignInException)
    }
    else {
      window.sessionStorage.setItem('__impersonationSessionKey', uniqueId());
      await props.sessionService.statelessSignInToken(ticket)
      .then((result => handleSignedIn(result, target, source)))
      .catch(handleSignInException);
    }
  };


  return (
    <div className={style['sign-in-container']}>
      {
        errorMessage &&
        <div className={auraStyle['aura-form-error-message']}
          data-testid='error-message'>
          {errorMessage}
        </div>
      }

      {
        signingIn &&
        <div className={style['auto-signin-container']}>
          <img
            alt='Logo'
            className={style['auto-signin-logo']}
            src='/icons/login/logo_bg.svg' />
          <img
            alt='Logo'
            className={style['auto-signin-loader']}
            src='/icons/login/loader.svg' />
          <span className={style['auto-signin-text']}>Please wait while we sign you in.</span>
        </div>
      }
    </div>
  )
}
