/**
 * @class CloseFormButton
 */

import * as React from 'react';

import styles from './close-form-button.module.css';
import auraStyle from '@mmodal/aura-web-style/dist/css/styles.module.css';
import auraIcons from '@mmodal/aura-icons/dist/css/auraicons.css';
import detailStyle from '../header/component-header-detail.module.scss';

export interface CloseFormButtonProps {
  onClick: (event: React.MouseEvent) => void,
};

export default function CloseFormButton(props: CloseFormButtonProps) {

  return (
    <button className={`${styles['close-form-button']} ${auraStyle['aura-transparent-button']} ${detailStyle['item']}`}
      onClick={props.onClick}
      data-testid='close-button'
    >
      <i className={['ai-x', auraIcons['ai-x']].join(' ')} />
    </button>
  );
}
