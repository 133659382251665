export enum Sources {
  MobileSDK = 'mobile-sdk',
  MUMSDev = 'mums-dev',
}

export enum Pages {
  Dictionary = 'dictionary',
  Commands = 'commands',
  Abbreviations = 'abbreviations',
  DeviceButtonMappings = 'device-button-mappings',
  MedicalSpecialty = 'specialty',
  Formatting = 'formatting-settings',
  Settings = 'user-settings',
  Shortcuts = 'shortcuts'
}