import React from 'react';
import ActivityIndicator from './activity-indicator';
import style from './activity-layer.module.scss';

interface ActivityLayerProps {
  activityRunning: boolean;
  fullScreen?: boolean;
  scrollable?: boolean;
  children: React.ReactElement;
  style?: React.CSSProperties;
}

export default function ActivityLayer(props: ActivityLayerProps) {
  return (
    <div style={props.style} className={props.scrollable ? style['activity-layer-container'] + " " + style['overflow']: style['activity-layer-container']}>
      {props.activityRunning &&
        <div className={style['activity-layer-indicator-container']}
          style={{'position': props.fullScreen ? 'fixed' : 'absolute'}}
          data-testid='activity-indicator-container'>
          <div className={style['icon-container']}>
            <ActivityIndicator />
          </div>
        </div>
      }
      {props.children}
    </div>
  );
}
