import auraStyle from '@mmodal/aura-web-style/dist/css/styles.module.css';
import { IDictionaryService, ImportResult } from '@mmodal/fd-api';
import FormFooter from '../footer/form-footer';
import { useState, useEffect, Fragment, useCallback } from 'react';
import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import AuthRedirect from '../authentication/auth-redirect';
import ActivityLayer from '../activity/activity-layer';
import style from './dictionary-import.module.scss'
import ReactTooltip from 'react-tooltip';
import { useDropzone } from 'react-dropzone';
import { UserNotificationLevel, NotificationCenter } from '../../notifications/notification-center';
import { handleAuthError } from '../util/error-handler';
import HeaderDetail from '../header/component-header-detail';

export type DeleteDictionaryProperties = {
  groupName?: string;
  dictionaryService: IDictionaryService;
  notificationCenter: NotificationCenter;
};

export default function DeleteDictionaryForm(props: DeleteDictionaryProperties) {
  const [needsAuthorized, setNeedsAuthorized] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [saveEnabled, setSaveEnabled] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<string>();
  const [secretMode, setSecretMode] = useState(false);
  const [files, setFiles] = useState([])
  const [pending, setPending] = useState(true);
  const [error, setError] = useState([]);
  const [report, setReport] = useState<ImportResult>();
  const [fileName, setFileName] = useState<string>();
  const [markAsHidden, setMarkAsHidden] = useState(false);
  const [acceptFiles, setAcceptFiles] = useState(['.csv']);

  const navigate = useNavigate();
  const location = useLocation();

  const onDrop = useCallback(acceptedFiles => {
    setFiles([...files, ...acceptedFiles])
  }, [])

  const { isDragActive, getRootProps, getInputProps } = useDropzone({ multiple: false, accept: acceptFiles, onDrop })

  useEffect(() => {
    ReactTooltip.rebuild();
    return function cleanup() { ReactTooltip.hide(); }
  });

  useEffect(() => {
    setSaveEnabled(files?.length > 0);
    setFileName(files?.length > 0 ? files[0].name : '')
  }, [files]);

  useEffect(() => {
    setSecretMode(location.state?.['secret']);
    setSelectedCategory(location.state?.['selectedCategory']);
    setMarkAsHidden(location.state?.['markAsHidden'])
    if (location.state?.['csvOnly']) { setAcceptFiles(['.csv']); setMarkAsHidden(true); }
    if (location.state?.['txtOnly']) { setAcceptFiles(['.txt']); setMarkAsHidden(true); }
  }, [location.state]);

  function onBulkDeleteAgainClicked() {
    setIsLoading(false);
    setPending(true);
    setReport(null);
    setError([]);
    setFileName(null);
    setFiles([]);
    navigate('/dictionary/bulk-delete', { state: { secret: secretMode, selectedCategory: selectedCategory }, replace: true });
  }

  function closeForm() {
    navigate(
      '/dictionary',
      { state: {
        selectedCategory: selectedCategory,
        secret: secretMode
      }, replace: true }
    );
  }

  function bulkDelete() {
    setIsLoading(true);
    setError(null);
    setPending(true);
    const formData = new FormData();
    formData.append("file", files[0]);
    formData.append("markAsHidden", markAsHidden ? "true": "false");
    props.dictionaryService.bulkDelete(formData).then((data) => {
      setIsLoading(false);
      setPending(false);
      setReport(data);
      setError(data.report);
    }).catch(handleError);
  }

  function handleError(reason: any) {
    setIsLoading(false);
    setPending(false);

    if (handleAuthError(reason)) {
      setNeedsAuthorized(true);
      return;
    }

    console.error(JSON.stringify(reason));

    props.notificationCenter.displayNotification({
      title: 'Dictionary Import Error',
      message: `${reason.message || ''}`,
      type: 'basic',
      level: UserNotificationLevel.Error
    });
  }

  if (needsAuthorized) {
    return <AuthRedirect />;
  }

  return (
    <div className={auraStyle['aura-dark-gray-form-container']}>
      <ActivityLayer activityRunning={isLoading}>
        <Fragment>
          <div className={auraStyle['aura-form-body']}>
            <HeaderDetail title="Delete Dictionary Items" iconType="light" screen="Dictionary" closeForm={closeForm} usingSecretMode={false} />
            <h5 className={[auraStyle['aura-form-description'], style['aura-form-description']].join(' ')}>
              Upload a Fluency Direct dictionary file to delete dictionary items
            </h5>
            {pending &&
              <div className={style['file-drop-zone']} {...getRootProps()}>
                <input {...getInputProps()} />
                {(!isDragActive && files?.length <= 0) && <div style={{ textAlign: 'center' }}>
                  <p>Drag and drop files here or</p>
                  <button className={auraStyle['aura-dark-button']}
                    data-testid={'browse-files-button'}>
                    Browse for {acceptFiles?.join(', ')} files
                  </button>
                </div>}

                {files.length > 0 &&
                  <div className={style['import-files-container']}>
                    <p>CSV to bulk delete dictionary</p>
                    <i className={[auraStyle['ai-file'], style['ai-file'], 'ai-file'].join(' ')}></i>
                    {files.map(file => (
                      <p className={style['file-name']} key={file.name}>
                        {file.name}
                      </p>
                    ))}
                    <p>
                      <a href="javascript:void(0);" className={style["select-link"]}>Select a different file</a>
                    </p>
                  </div>
                }
              </div>
            }

            {
              !pending && report &&
              <div className={style['report-summary']}>
                {
                  (report.totalCount - report.failureCount) > 0 && !report.errors && <div className={style['report-success-summary']}>
                    Successfully deleted {report.totalCount - report.failureCount} from "{fileName}"
                    </div>
                }
                {
                  report.errors &&
                  <><div className={style['report-error-summary']}>
                    We were unable to delete from "{fileName}"
                  </div>
                  <div className={style['report-error-list']}>
                    {report.errors?.split('\n').map(err => {
                      return <div className={style['report-error-item']}>
                        {err}
                      </div>
                    })}
                  </div>
                  </>
                }
                {
                  !report.errors && error && error.length > 0 &&
                  <><div className={style['report-error-summary']}>
                    We were unable to delete the following {report.failureCount} items from "{fileName}"
                  </div>
                  <div className={style['report-error-list']}>
                    {error.map(err => {
                      return <div className={style['report-error-item']}>
                        {err}
                      </div>
                    })}
                  </div>
                  </>
                }
              </div>
            }

            <FormFooter
              onSaveClick={bulkDelete}
              saveText={'Bulk Delete'}
              hideSave={!!report}
              cancelText={report ? 'Close' : 'Cancel'}
              onCancelClick={closeForm}
              saveEnabled={saveEnabled}
              showAddAnother={false}>
              <div style={{display: 'flex', flexDirection: 'row', alignItems:'center', width: 'fit-content'}}>
                {secretMode && !report && <>
                  <h5 style={{
                    color: 'white',
                    width: 'auto'
                  }}>
                    Mark as hidden
                  </h5>
                  <input type='checkbox'
                    data-testid={'add-another-check'}
                    style={{ width: 'auto' }}
                    checked={markAsHidden}
                    id='markAsHidden'
                    onChange={(e) => setMarkAsHidden(e.target.checked)}
                  />
                </>}
                {!!report && <button
                  tabIndex={-1}
                  onClick={onBulkDeleteAgainClicked}
                  className={[auraStyle['aura-flat-button'], style['flat-button']].join(' ')}>
                  <span>Bulk delete again</span>
                </button>}
              </div>
            </FormFooter>
          </div>
        </Fragment>
      </ActivityLayer>
    </div >
  );
}
