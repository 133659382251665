import React, { useEffect, useState, useCallback } from 'react';
import { NotificationCenter, IdentifiableUserNotification, UserNotificationLevel } from '../../notifications/notification-center';
import style from './toast-alert-container.module.scss';
import ToastAlert, { ToastAlertType } from './toast-alert';

interface ToastAlertContainerProps {
  notificationCenter: NotificationCenter;
}

export default function ToastAlertContainer(props: ToastAlertContainerProps) {
  const [toastAlerts, setToastAlerts] = useState<IdentifiableUserNotification[]>([]);

  const handleDismissToastAlert = useCallback((toastId: string) => {
    const newToasts = [...toastAlerts];
    const index = newToasts.indexOf(newToasts.find(un => un.id === toastId));
    if (index < 0) {
      // Toast not found
      return;
    }
    newToasts.splice(index, 1);
    setToastAlerts(newToasts);
  }, [toastAlerts]);

  const handleDismissAllAlerts = useCallback(() => {
    setToastAlerts([]);
  }, [toastAlerts]);

  useEffect(() => {
    let handleDisplayToastAlert = (toastAlert: IdentifiableUserNotification) => {
      const newToasts = [...toastAlerts];
      newToasts.push(toastAlert);
      setToastAlerts(newToasts);
    };

    props.notificationCenter.displayEvent.subscribe(handleDisplayToastAlert);

    return () => {
      props.notificationCenter.displayEvent.unsubscribe(handleDisplayToastAlert)
    };
  }, [props.notificationCenter.displayEvent, toastAlerts]);

  useEffect(() => {
    props.notificationCenter.dismissEvent.subscribe(handleDismissToastAlert);

    return () => {
      props.notificationCenter.dismissEvent.unsubscribe(handleDismissToastAlert);
    }
  }, [props.notificationCenter.dismissEvent, handleDismissToastAlert]);

  useEffect(() => {
    props.notificationCenter.dismissAllEvent.subscribe(handleDismissAllAlerts);

    return () => {
      props.notificationCenter.dismissAllEvent.unsubscribe(handleDismissAllAlerts);
    }
  }, [props.notificationCenter.dismissAllEvent, handleDismissAllAlerts]);

  return (
    <div
      data-testid='notification-container'
      className={style['notification-container']}>
      {toastAlerts.map(ta => createToast(ta))}
    </div>
  );

  function createToast(details: IdentifiableUserNotification): JSX.Element {
    return (
      <ToastAlert
        key={details.id}
        id={details.id}
        title={details.title}
        detail={details.message}
        actionText={details.actionText}
        type={convertNotificationLevel(details.level)}
        onAction={() => details.action()}
        onDismiss={(id) => handleDismissToastAlert(id)} />
    );
  }

  function convertNotificationLevel(level: UserNotificationLevel): ToastAlertType {
    switch (level) {
      case UserNotificationLevel.Confirmation:
        return ToastAlertType.Confirmation;
      case UserNotificationLevel.Information:
        return ToastAlertType.Information;
      case UserNotificationLevel.Warning:
        return ToastAlertType.Warning;
      case UserNotificationLevel.Error:
        return ToastAlertType.Error;
      default:
        return ToastAlertType.Information;
    }
  }
}
