import * as React from 'react';
import auraStyle from '@mmodal/aura-web-style/dist/css/styles.module.css';
import style from './error-fallback.module.css';

export default function ErrorFallback({ error, resetErrorBoundary }: any) {
  return (
    <div className={[style['aura-alert'], auraStyle['error-message']].join(' ')}>
      <div className={style['aura-alert-text']}>
        <p>Something went wrong:</p>
        <div>{error.message}</div>
        <div className="card-body">
          <pre>{error && error.stack.toString()}</pre>
        </div>
        <button style={{ width: 100 }} onClick={resetErrorBoundary}>Try again</button>
      </div>
    </div>
  )
}
