import auraStyle from '@mmodal/aura-web-style/dist/css/styles.module.css';
import style from './import-button-mappings-form.module.scss'
import { ButtonMappingsService, ImportResult } from '@mmodal/fd-api'
import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useNavigate, useLocation } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import { NotificationCenter, UserNotificationLevel } from '../../notifications/notification-center'
import ActivityLayer from '../activity/activity-layer';
import AuthRedirect from '../authentication/auth-redirect'
import FormFooter from '../footer/form-footer';
import HeaderDetail from '../header/component-header-detail';

export interface ImportButtonMappingsProperties {
  buttonMappingsService: ButtonMappingsService
  notificationCenter: NotificationCenter
}

export default function ImportButtonMappingsForm(props: ImportButtonMappingsProperties) {
  const navigate = useNavigate()
  const location = useLocation()

  const [secretMode, setSecretMode] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [saveEnabled, setSaveEnabled] = useState(false)
  const [needsAuthorized, setNeedsAuthorized] = useState(false)
  const [files, setFiles] = useState([]);
  const [pending, setPending] = useState(true);
  const [error, setError] = useState([]);
  const [report, setReport] = useState<ImportResult>();
  const [fileName, setFileName] = useState<string>();
  const [acceptFiles, ] = useState(['.fdb']);

  const onDrop = useCallback(acceptedFiles => {
    setFiles([...files, ...acceptedFiles])
  }, [])

  const { isDragActive, getRootProps, getInputProps } = useDropzone({ multiple: false, accept: acceptFiles, onDrop })

  // Rebuild tooltips whenever state changes
  useEffect(() => {
    ReactTooltip.rebuild()

    return () => {
      ReactTooltip.hide()
    }
  })

  // Update whether user can save when files change
  useEffect(() => {
    setSaveEnabled(files?.length > 0)
    setFileName(files?.length > 0 ? files[0].name : '')
  }, [files])

  // Update secret mode based on location state
  useEffect(() => {
    setSecretMode(location.state?.['secret'])
  }, [location.state])


  function onImportAnotherFileClicked()  {
    setIsLoading(false);
    setPending(true);
    setReport(null);
    setError([]);
    setFileName(null);
    setFiles([]);
    navigate('/buttonmappings/import', { state: { secret: secretMode }});
  }

  const closeForm = () => {
    navigate('/buttonmappings', { state: { secret: secretMode }})
  }

  const importButtonMappings = () => {
    setIsLoading(true)
    setError(null)

    const formData = new FormData()
    formData.append("file", files[0])

    props.buttonMappingsService.importAll(formData)
      .then((data) => {
        setIsLoading(false);
        setIsLoading(false);
        setPending(false);
        setReport(data);
        setError(data.report);
      })
      .catch(handleError)
  }

  const handleError = (reason: any) => {
    setIsLoading(false)

    if (reason.status === 401) {
      setNeedsAuthorized(true)
      return
    }

    props.notificationCenter.displayNotification({
      title: 'Button Mapping Import Error',
      message: 'Unable to import button mappings. Please try again or contact support if the problem persists.',
      type: 'basic',
      level: UserNotificationLevel.Error,
    })
  }

  if (needsAuthorized) {
    return <AuthRedirect />
  }

  return (
    <div className={auraStyle['aura-dark-gray-form-container']}>

      <ActivityLayer activityRunning={isLoading}>

        <Fragment>

          <div className={auraStyle['aura-form-body']}>

          <HeaderDetail title="Import Device Button Mappings" iconType="light" screen="Device Button Mappings" closeForm={closeForm} usingSecretMode={false} />
          
            <h5 className={[auraStyle['aura-form-description'], style['aura-form-description']].join(' ')}>
              Upload a Fluency Direct button mappings file to import its device button mappings
            </h5>

            { pending &&
              <div className={style['file-drop-zone']} {...getRootProps()}>
                <input {...getInputProps()} />
                {(!isDragActive && files?.length <= 0) && <div style={{ textAlign: 'center' }}>
                  <p>Drag and drop files here or</p>
                  <button className={auraStyle['aura-dark-button']}
                    data-testid={'browse-files-button'}>
                    Browse for {acceptFiles?.join(', ')} files
                  </button>
                </div>}

                {files.length > 0 &&
                  <div className={style['import-files-container']}>
                    <p>File to import</p>
                    <i className={[auraStyle['ai-file'], style['ai-file'], 'ai-file'].join(' ')}></i>
                    {files.map(file => (
                      <p className={style['file-name']} key={file.name}>
                        {file.name}
                      </p>
                    ))}
                    <p>
                      <a href="javascript:void(0);" className={style["select-link"]}>Select a different file</a>
                    </p>
                  </div>
                }
              </div>
            }

            {
              !pending && report &&
              <div className={style['report-summary']}>
                {
                  (report.totalCount - report.failureCount) > 0 && !report.errors && <div className={style['report-success-summary']}>
                    Successfully imported {report.totalCount - report.failureCount} from "{fileName}"
                    </div>
                }
                {
                  report.errors &&
                  <><div className={style['report-error-summary']}>
                    We were unable to import from "{fileName}"
                  </div>
                  <div className={style['report-error-list']}>
                    {report.errors?.split('\n').map(err => {
                      return <div className={style['report-error-item']}>
                        {err}
                      </div>
                    })}
                  </div>
                  </>
                }
                {
                  !report.errors && error && error.length > 0 &&
                  <><div className={style['report-error-summary']}>
                    We were unable to import the following {report.failureCount} items from "{fileName}"
                  </div>
                  <div className={style['report-error-list']}>
                    {error.map(err => {
                      return <div className={style['report-error-item']}>
                        {err}
                      </div>
                    })}
                  </div>
                  </>
                }
              </div>
            }

            <FormFooter
              onSaveClick={importButtonMappings}
              saveText={'Import'}
              hideSave={!!report}
              cancelText={report ? 'Close': 'Cancel'}
              onCancelClick={closeForm}
              saveEnabled={saveEnabled}
              showAddAnother={false}>
               <div style={{display: 'flex', flexDirection: 'row', alignItems:'center', width: 'fit-content'}}>
                {/* {secretMode && !report && <>
                  <h5 style={{
                    color: 'white',
                    width: 'auto'
                  }}>
                    Mark as hidden
                  </h5>
                  <input type='checkbox'
                    data-testid={'add-another-check'}
                    style={{ width: 'auto' }}
                    checked={markAsHidden}
                    id='markAsHidden'
                    onChange={(e) => setMarkAsHidden(e.target.checked)}
                  />
                </>} */}
                {!!report && <button
                  tabIndex={-1}
                  onClick={onImportAnotherFileClicked}
                  className={[auraStyle['aura-flat-button'], style['flat-button']].join(' ')}>
                  <span>Import another file</span>
                </button>}
              </div>
            </FormFooter>
          </div>
        </Fragment>
      </ActivityLayer>
    </div >
  );
}
