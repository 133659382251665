import { IStorage } from '@mmodal/fd-api';

export class WebStorage implements IStorage {

  public static shouldUseSession = () => {
    return window.sessionStorage.getItem('__impersonationSessionKey') !== null;
  };

  public static getStorage(): Storage {
    return WebStorage.shouldUseSession() ? window.sessionStorage : window.localStorage;
  }

  syncStore = {
    add(item: any): Promise<void> {
      return new Promise((resolve) => {
        Object.keys(item).forEach((key) => {
          if (item[key] !== null)
              WebStorage.getStorage().setItem(key, item[key]);
        });
        resolve();
      });
    },
    get(id: any): Promise<any> {
      return new Promise((resolve) => {
        resolve(WebStorage.getStorage().getItem(id));
      });
    },
    remove(id: any): Promise<void> {
      return new Promise((resolve) => {
        WebStorage.getStorage().removeItem(id);
        resolve();
      });
    },
    clear: () => {
      WebStorage.getStorage().clear();
    },
  };

  localStore = {
    add(item: any): Promise<void> {
      return new Promise((resolve) => {
        Object.keys(item).forEach((key) => {
          if (item[key] !== null)
            WebStorage.getStorage().setItem(key, item[key]);
        });
        resolve();
      });
    },
    get(id: any): Promise<any> {
      return new Promise((resolve) => {
        resolve(WebStorage.getStorage().getItem(id));
      });
    },
    remove(id: any): Promise<void> {
      return new Promise((resolve) => {
        WebStorage.getStorage().removeItem(id);
        resolve();
      });
    },
    clear: () => {
      WebStorage.getStorage().clear();
    },
  };
}

const storage = new WebStorage();
export default storage;
