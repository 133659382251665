import React from 'react';
import { useNavigate } from 'react-router-dom';
import leftNav from './left-nav.module.scss';
import auraStyle from '@mmodal/aura-web-style/dist/css/styles.module.css';

interface NavItemProperties {
  to: string,
  label: string,
  selected?: boolean,
  beforeNavigation?: () => Promise<void>;
}

export default function NavItem(props: NavItemProperties) {
  const navigate = useNavigate();

  return (
    <div className={[auraStyle['aura-category-list-item'], leftNav['aura-category-list-item-adjustment'], props.selected ? auraStyle['selected'] : ''].join(' ')}
      onClick={async (e: React.MouseEvent) => {
        if (props.beforeNavigation) {
          // Need to persist the event so it isn't released from memory by accident
          e.persist();
          await props.beforeNavigation();
        }
        navigate({pathname: props.to}, {state: { secret: e.shiftKey }});
      }}
      data-testid={props.label}>
      <div className={[auraStyle['aura-text'], leftNav['link-adjustment']].join(' ')}>
        {props.label}
      </div>
    </div>
  );
}