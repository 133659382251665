import { IServerConfig } from "@mmodal/fd-api";

export const config: IServerConfig = {
  appType: 'app',
  serverUrl: window.location.origin,
  serverVersion: 'v0.1',
  apiUrl:  window.location.origin,
  apiVersion: 'v1',
  devOptions: {
    standaloneMode: process.env.REACT_APP_STANDALONE_MODE === 'true'
  }
};