import { INotificationCenter, IUserNotification } from "@mmodal/fd-api";
import { v4 } from 'uuid';
import { EventEmitter } from '../events/event-emitter';

export enum UserNotificationLevel {
  Confirmation,
  Information,
  Warning,
  Error,
}

export interface UserNotification extends IUserNotification {
  level: UserNotificationLevel;
  actionText?: string;
  action?: () => void;
}

export interface IdentifiableUserNotification extends UserNotification {
  id: string;
}

export class NotificationCenter implements INotificationCenter {
  public displayEvent: EventEmitter<IdentifiableUserNotification> = new EventEmitter();
  public dismissEvent: EventEmitter<string> = new EventEmitter();
  public dismissAllEvent: EventEmitter<boolean> = new EventEmitter();

  private constructor() {
    console.log('Notification center');
  }
  private static instance: NotificationCenter;  

  static getInstance() {  
    if (!NotificationCenter.instance) {  
      NotificationCenter.instance = new NotificationCenter();  
    }  
    return NotificationCenter.instance;  
  }  

  displayNotification(details: UserNotification): string {
    const uuid = v4();
    const notification: IdentifiableUserNotification = Object.assign({}, details, { id: uuid });
    this.displayEvent.fire(notification);
    return uuid
  }

  displayTemporaryNotification(details: UserNotification, millisToDisplay: number = 3000): void {
    const uuid = v4();
    const notification: IdentifiableUserNotification = Object.assign({}, details, { id: uuid });
    this.displayEvent.fire(notification);

    window.setTimeout(() => this.dismissNotification(uuid), millisToDisplay)
  }

  dismissNotification(id: string): void {
    this.dismissEvent.fire(id);
  }

  dismissAllNotifications(): void {
    this.dismissAllEvent.fire(true);
  }
}
