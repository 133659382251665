import React, { Fragment } from 'react';
import style from './toast-alert.module.scss';

export enum ToastAlertType {
  Confirmation,
  Information,
  Warning,
  Error,
}

interface ToastAlertProperties {
  id: string;
  type: ToastAlertType;
  title: string;
  detail?: string;
  actionText?: string;
  onDismiss: (toastId: string) => void;
  onAction: () => void;
}

export default function ToastAlert(props: ToastAlertProperties) {
  return (
    <div
      data-testid='card'
      className={[style['card'], getCardClass()].join(' ')}
      data-toast-id={props.id}>
      <img
        data-testid='icon'
        alt='icon'
        src={getIconUrl()}
        className={[style['icon'], getIconClass()].join(' ')} />
      <div
        className={style['body']}>
        <div
          className={style['title']}>
          {props.title}
        </div>
        {
          props.detail &&
          <Fragment>
            <div
              data-testid='detail'
              className={style['detail']}>
              {props.detail}
            </div>
            {props.actionText &&
              <div
                data-testid='action'
                className={style['action']}
                onClick={props.onAction}>
                {props.actionText}
              </div>}
          </Fragment>
        }
      </div>
      {
        !props.detail && props.actionText &&
        <div
          data-testid='action-right'
          className={[style['action'], style['vertical-center']].join(' ')}
          onClick={props.onAction}>
          {props.actionText}
        </div>
      }
      <button
        data-testid='delete-button'
        className={style['delete-button']}
        onClick={() => props.onDismiss(props.id)}>
        <img
          alt='dismiss icon'
          src='/icons/notification/dismiss.svg' />
      </button>
    </div>
  );

  function getIconUrl(): string {
    switch (props.type) {
      case ToastAlertType.Confirmation:
        return '/icons/notification/confirmation.svg';
      case ToastAlertType.Information:
        return '/icons/notification/information.svg';
      case ToastAlertType.Warning:
        return '/icons/notification/warning.svg';
      case ToastAlertType.Error:
        return '/icons/notification/error.svg';
    }
  }

  function getCardClass(): string {
    switch (props.type) {
      case ToastAlertType.Confirmation:
        return style['confirmation'];
      case ToastAlertType.Information:
        return style['information'];
      case ToastAlertType.Warning:
        return style['warning'];
      case ToastAlertType.Error:
        return style['error'];
    }
  }

  function getIconClass(): string {
    if (!props.detail) {
      return style['vertical-center'];
    } else {
      return '';
    }
  }
}
