const credentials: string = 'index.php?user=fdwebuser&passw=$2y$10$A4o4.r6o3bzfMEjrAnQwcOhqIb4xYp8g5Bsp8KXxAkJrvV63gKeuW';

export function supportSwitch(screen: string) {
  switch(screen) {
    case 'Dictionary':
    case 'Manage dictionary':
      return `fd-dictionary-web/${credentials}`;
    case 'Abbreviations':
    case 'Manage abbreviations':
      return `fd-abbreviations-web/${credentials}`;
    case 'Commands':
    case 'Manage commands':
      return `fd-commands-web/${credentials}`;
    case 'Device Button Mappings':
    case 'Manage device buttons':
      return `fd-device-button-mappings-web/${credentials}`;
    case 'Medical Specialty':
    case 'Select your medical specialty':
      return `fd-medical-specialty-web/${credentials}`;
    case 'Formatting':
    case 'Adjust formatting':
      return `fd-formatting-web/${credentials}`;
    case 'Account Settings':
    case 'Manage settings':
      return `fd-account-settings-web/${credentials}`;
    case 'Signing Out':
      return `fd-signing-out-web/${credentials}`;
    case 'Signing In':
      return `fd-signing-in-web/${credentials}`;
    case 'Getting Started':
      return `fd-getting-started-web/${credentials}`;
    case 'Home':
      return `${credentials}`;
    default:
      return `${credentials}`;
  }
}