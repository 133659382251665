import React from 'react';
import style from './component-header.module.scss';
import detailStyle from './component-header-detail.module.scss';
import { supportSwitch } from '../util/support-links';
import CloseFormButton from '../buttons/close-form-button';
import auraStyle from '@mmodal/aura-web-style/dist/css/styles.module.css';
import sharedStyle from '../styles.module.css'

export interface ComponentHeaderProps {
  usingSecretMode: boolean;
  title: string;
  screen: string;
  iconType: string;
  closeForm(): void;
};

export default function HeaderDetail(props: ComponentHeaderProps) {
  return(
    <div className={`${detailStyle['headerDisplay']}`}>
      <h2>{props.title}</h2>
      <button className={style['helpButtonDesktop']}
        onClick={() => {window.open('https://docs.mmodal.com/home/index.php/fd-user-guides/fluency-direct-web/'+supportSwitch(props.screen), '_blank')}}>
        <img className={style['helpDesktop']} src={props.iconType === 'light' ? '../../header-help.svg' : '../../question-circle.svg'} alt="Help" />
      </button>
      {props.usingSecretMode && (
        <div className={sharedStyle['aura-badge']} data-testid={'secretModeBadge'}>
          <label
            title='Secret mode'
            className={[auraStyle['aura-badge'], sharedStyle['aura-badge-purple']].join(' ')}>
            {'Secret mode'}
          </label>
        </div>
      )}
      {props.closeForm !== null  && (<CloseFormButton onClick={props.closeForm} />)}
    </div>
  );
}